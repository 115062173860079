import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Blue Bobbinelle</h1>
        <p>
          <center>
            Embroidery & Design
          </center>
        </p>

      </header>
    </div>
  );
}

export default App;
